export const createPatientsHref = () => {
  return "/patient" as const;
};
export const createPatientHref = (id: string) => {
  return `/patient/${id}` as const;
};
export const createCreatePatientHref = () => {
  return "/patient/create" as const;
};

export const createSchedulePageHref = () => {
  return "/schedule" as const;
};
export const createClinicPageHref = () => {
  return "/clinic" as const;
};

export const createClinicSubPageHref = (subPage: string) => {
  return `${createClinicPageHref()}/${subPage}` as const;
};

export const createSettingsPageHref = () => {
  return "/settings" as const;
};

export const createSettingsSubPageHref = (subPage: "macros") => {
  return `${createSettingsPageHref()}/${subPage}` as const;
};

export const createPatientSubPageHref = (
  id: string,
  subPage:
    | "profile"
    | "messaging"
    | "appointments"
    | "insurance"
    | "cases"
    | "notes"
    | "files"
    | "",
) => {
  return `${createPatientHref(id)}/${subPage}` as const;
};
export const createPatientCreateCasePageHref = (id: string) => {
  return `${createPatientSubPageHref(id, "cases")}/create` as const;
};

export const createPatientCasePageHref = (id: string, caseId: string) => {
  return `${createPatientSubPageHref(id, "cases")}/${caseId}` as const;
};

export const createPatientCreateInsuracePageHref = (id: string) => {
  return `${createPatientSubPageHref(id, "insurance")}/create` as const;
};
export const createPatientInsurancePageHref = (
  id: string,
  insuranceId: string,
) => {
  return `${createPatientSubPageHref(id, "insurance")}/${insuranceId}` as const;
};

export const createAppointmentHref = (appointmentId: string) => {
  return `/appointment/${appointmentId}` as const;
};

export const createAppointmentSubPageHref = (
  appointmentId: string,
  subPage:
    | "subjective"
    | "objective"
    | "assessment"
    | "plan"
    | "diagnosis_treatment"
    | "signatures"
    | "bill"
    | "tasks"
    | "summary"
    | "",
) => {
  return `${createAppointmentHref(appointmentId)}/${subPage}` as const;
};

export const createEditMacroHref = (macroId: string) => {
  return `/macro/${macroId}` as const;
};

export const createBillsHref = () => {
  return "/bill" as const;
};

export const createBillHref = (billId: string) => {
  return `/bill/${billId}` as const;
};

export const createDayScheduleHref = (options?: {
  year: string;
  month: string;
  day: string;
  appointmentId?: string;
}) => {
  return `${createSchedulePageHref()}/day${
    options ? `/${options.year}/${options.month}/${options.day}` : ""
  }${options?.appointmentId ? `?aid=${options.appointmentId}` : ""}` as const;
};

export const createMonthScheduleHref = (options?: {
  year: string;
  month: string;
  day: string;
  appointmentId?: string;
}) => {
  return `${createSchedulePageHref()}/month${
    options ? `/${options.year}/${options.month}/${options.day}` : ""
  }${options?.appointmentId ? `?aid=${options.appointmentId}` : ""}` as const;
};

export const createWeekScheduleHref = (options?: {
  year: string;
  month: string;
  day: string;
  appointmentId?: string;
}) => {
  return `${createSchedulePageHref()}/week${
    options ? `/${options.year}/${options.month}/${options.day}` : ""
  }${options?.appointmentId ? `?aid=${options.appointmentId}` : ""}` as const;
};

export const createCompareScheduleHref = (options?: {
  year: string;
  month: string;
  day: string;
  appointmentId?: string;
}) => {
  return `${createSchedulePageHref()}/compare${
    options ? `/${options.year}/${options.month}/${options.day}` : ""
  }${options?.appointmentId ? `?aid=${options.appointmentId}` : ""}` as const;
};

export const createTaskPageHref = () => {
  return "/task" as const;
};

export const createMyCreatedTasksPageHref = () => {
  return `${createTaskPageHref()}/created` as const;
};

export const createCompletedTasksPageHref = () => {
  return `${createTaskPageHref()}/completed` as const;
};
export const createCompletedMyCreatedTasksPageHref = () => {
  return `${createMyCreatedTasksPageHref()}/completed/created` as const;
};

export const createEobJobsPageHref = () => {
  return createBillsHref() + "/eob";
};

export const createEobJobPageHref = (eobJobId: string) => {
  return `${createEobJobsPageHref()}/${eobJobId}` as const;
};

export const createAcceptClinicStaffInvitationPageHref = (
  invitationId: string,
) => {
  return `/invitation/clinic/${invitationId}` as const;
};

export const createSignInPageHref = () => {
  return "/api/auth/signin" as const;
};

export const createBillDetailsPageHref = (billId: string) => {
  return `${createBillHref(billId)}/details` as const;
};

export const createResetPasswordPageHref = () => {
  return "/reset-password" as const;
};

export const createAnalyticsPageHref = () => {
  return "/analytics" as const;
};

export const createPatientSecureMessageHref = (token: string) => {
  return `/patientMessage?token=${token}` as const;
};
